import React, { useEffect, useRef } from "react"
import { PageProps } from "gatsby"
import firebase from "gatsby-plugin-firebase"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/gatsby-images/services-page";

import {
  BannerContainer, Subtitle, Title, BannerImageContainer,
  Section1Container, HelpWrap, HelpText, HelpActionWrap, HelpActionText,
  ServiceWrap, ServiceContainer, ServiceTextArea, ServiceImgGrid, ImgGridItem,
  ServiceSlideshowImg
} from "../styles/services";
import ServicesBg from "../svgs/servicesbg.svg";
import DATA from "./../data/services";
import gsap from "gsap";

const Banner = () => {
  const subTitleRef = useRef(null);
  const titleRef = useRef(null);

  useEffect(() => {
    gsap.to(subTitleRef.current, {
        duration: .5,
        delay: .5,
        opacity: 1,
        y: 0,
        ease: "power3.easeOut"
      });
    gsap.to(titleRef.current, {
      duration: .5,
      delay: .7,
      opacity: 1,
      y: 0,
      ease: "power3.easeOut"
    });
    gsap.to(".services-bg", {
      duration: .7,
      delay: .3,
      opacity: 1,
      y: 0,
      ease: "power3.easeOut"
    });
  }, []);

  return (
    <BannerContainer>
      <ServicesBg className="services-bg" />
      <Subtitle ref={subTitleRef}>What we do?</Subtitle>
      <Title ref={titleRef}>We Reinvent Possibilities</Title>
    </BannerContainer>
  )
}

const Section1 = () => (
  <Section1Container>
    <HelpWrap>
      <HelpText>Collaborating closely with our clients, we design user-friendly software for multi-platform experiences and enterprise applications.</HelpText>
    </HelpWrap>
    <HelpActionWrap>
      <HelpActionText>Our success lies in our principled approach.</HelpActionText>
    </HelpActionWrap>
  </Section1Container>
)

const ServiceItem = ({ data, isEven }) => (
  <ServiceWrap>
    <ServiceContainer>
      <ServiceTextArea>
        <h2>{data.title}</h2>
        <div className={`text-container${isEven ? ' isEven' : ''}`}>
          <ul>
            {
              data.workingSteps.map((i, index) => <li><div>{index + 1}</div><div>{i}</div></li>)
            }
          </ul>
          <p className="description-txt">
            <ServiceSlideshowImg>
              <Image imageName={data.banner} />
            </ServiceSlideshowImg>
            {data.description}
          </p>
        </div>
      </ServiceTextArea>
    </ServiceContainer>
  </ServiceWrap>
)

const ServicesPage: React.FC<PageProps<any>> = ({ data, path }) => {
  React.useEffect(() => {
    if (firebase) {
      firebase.analytics().logEvent('visited_services')
    }
  }, [firebase]);

  return (
    <Layout>
      <SEO title="Services" />
      <Banner />
      <BannerImageContainer>
        <Image imageName="servicesbanner" />
      </BannerImageContainer>
      <Section1 />
      {
        DATA.map((i, index) => <ServiceItem data={i} isEven={index % 2 === 0} />)
      }
      <div style={{height: '50px'}} />
    </Layout>
  );
}

export default ServicesPage
