import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({ imageName }) => {
  const data: any = useStaticQuery(graphql`
    query {
      servicesbanner: file(relativePath: { eq: "service-banner.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 572) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      uxBanner: file(relativePath: { eq: "services/ux-banner.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 572) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      designSystem: file(relativePath: { eq: "services/design-system.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 572) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      research: file(relativePath: { eq: "services/research.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 572) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data[imageName].childImageSharp.fluid) {
    return <div>Picture not found</div>
  }

  return <Img fluid={data[imageName].childImageSharp.fluid} imgStyle={{
    objectFit: "cover"
  }} />
}

export default Image
