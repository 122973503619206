const DATA = [
  {
    title: 'User Experience Design',
    workingSteps: [
      'Complex Applications',
      'Proof of concept',
      'Service Design',
      'Design Prototyping',
      'Usability Testing'
    ],
    description: 'Focused on designing multi-platform experiences and enterprise applications, we work in iterative sprints, using core UX patterns and principles to guide our work. Everest is unique in our ability to move faster than other agencies, while producing world-class interfaces.',
    banner: "uxBanner"
  },
  {
    title: 'Design Systems',
    workingSteps: [
      'Atomic Design',
      'Component libraries',
      'Delivery specifications',
      'Handoff and training',
      'Implementation assurance'
    ],
    description: 'Complex application interfaces require detailed Design Systems to implement quickly and consistently. We work to ensure that design is never a bottleneck for your development process. Your engineers will love our component-based systems and meticulously documented design handoff process.',
    banner: "designSystem"
  },
  {
    title: 'Discovery & User Research',
    workingSteps: [
      'Ethnographic Research',
      'Segmentation Studies',
      'Heuristic Evaluations',
      'Observational Interviews',
      'Market Research'
    ],
    description: 'Our Discovery process is designed to create value within the first week. We begin partnerships by creating a shared understanding of user experience principles to shape the work we do together.  Within a month, we deliver a clear strategy and product requirements document that encompasses user engagement opportunities, design foundations, and delivery structures.',
    banner: "research"
  }
];

export default DATA;