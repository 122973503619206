import styled from "styled-components"

export const BannerContainer = styled.div`
  height: calc(100vh - 100px);
  width: 100%;
  padding: 0 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .services-bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    opacity: 0;
    transform: translateY(150px);
  }

  @media (max-width: 599px) {
    height: auto;
    padding: 0 25px;
    margin-top: 100px;
  }
  @media (min-width: 600px) {
    height: auto;
    padding: 0 35px;
    margin-top: 100px;
  }
  @media (min-width: 900px) {
    height: calc(100vh - 100px);
    padding: 0 130px;
    margin-top: 0;
  }
`

export const Subtitle = styled.h3`
  color: #727272;
  font-size: 3.125rem;
  font-weight: 400;
  margin-bottom: 40px;
  opacity: 0;
  transform: translate3d(0, 150px, 0);

  @media (max-width: 599px) {
    font-size: 2.75rem;
    margin-bottom: 20px;
  }
  @media (min-width: 600px) {
    font-size: 3rem;
    margin-bottom: 30px;
  }
  @media (min-width: 900px) {
    font-size: 3.125rem;
    margin-bottom: 40px;
  }
`

export const Title = styled.h1`
  font-size: 4.5rem;
  font-weight: 500;
  text-align: left;
  opacity: 0;
  transform: translate3d(0, 150px, 0);

  @media (max-width: 599px) {
    font-size: 3rem;
  }
  @media (min-width: 600px) {
    font-size: 4.5rem;
  }
  @media (min-width: 900px) {
    font-size: 4.5rem;
  }
`
export const BannerImageContainer = styled.div`
  height: 575px;
  width: calc(100% - 260px);
  margin: 0 130px 100px;
  overflow: hidden;

  @media (max-width: 599px) {
    width: 100%;
    margin: 50px 0;
    height: auto;
  }
  @media (min-width: 600px) {
    width: 100%;
    margin: 50px 0;
    height: auto;
  }
  @media (min-width: 900px) {
    width: calc(100% - 260px);
    margin: 0 130px;
    height: 575px;
  }
`

export const Section1Container = styled.div`
  min-height: 40vw;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100px;

  @media (max-width: 599px) {
    min-height: 30vh;
  }
  @media (min-width: 600px) {
    min-height: 30vh;
  }
  @media (min-width: 900px) {
    min-height: 40vw;
  }
`
export const HelpWrap = styled.div`
  width: 57.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 599px) {
    width: 90%;
  }
  @media (min-width: 600px) {
    width: 80%;
  }
  @media (min-width: 900px) {
    width: 57.5%;
  }
`
export const HelpText = styled.span`
  font-size: 1.5rem;
  line-height: 3rem;
  font-weight: 300;
  text-align: center;
  color: #19191b;
`
export const HelpActionWrap = styled.span`
  padding: 30px 0;
`
export const HelpActionText = styled.span`
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.3rem;
  color: #aeaeaf;
`

export const ServiceWrap = styled.section`
  padding-bottom: 50px;
`

export const ServiceContainer = styled.div`
  margin: auto;
  width: 73.33333vw;
  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
    width: calc(100vw - 50px);
  }
  @media (min-width: 600px) {
    width: calc(100vw - 60px);
  }
  @media (min-width: 900px) {
    width: 73.33333vw;
  }
`

export const ServiceTextArea = styled.div`
  width: 100%;
  padding: 5.95238vw 0 0;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: -0.02976vw;
  color: #19191b;
  border-top: 1px solid #f0f0f0;

  h2 {
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 50px;
  }

  .text-container {
    display: flex;
    justify-content: space-between;

    &.isEven {
      flex-direction: row-reverse;

      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        li {
          width: 50%;
        }
      }
    }

    @media (max-width: 599px) {
      &.isEven {
        flex-direction: column;
        ul {
          align-items: flex-start;
        }
      }
    }
    @media (min-width: 600px) {
      &.isEven {
        flex-direction: column;
        ul {
          align-items: flex-end;
        }
      }
    }
    @media (min-width: 900px) {
      &.isEven {
        flex-direction: row-reverse;
        ul {
          align-items: flex-end;
        }
      }
    }
  }

  .description-txt {
    flex: 1;
    font-size: 1.2rem;
    line-height: 1.4rem;
    letter-spacing: -0.0119vw;
    font-weight: 300;
    color: #aeaeaf;
    padding-top: 20px;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 40%;

    li {
      padding: 1.13095vw 0 0;
      div:first-child {
        float: left;
        width: 1.42857vw;
        font-size: 0.75rem;
        line-height: 1.3rem;
        color: #aeaeaf;
        font-weight: 500;
      }
      div:last-child {
        float: left;
        font-size: 0.9rem;
        line-height: 1.3rem;
        color: #373739;
        font-weight: 300;
      }
    }
  }

  @media (max-width: 599px) {
    .text-container {
      flex-direction: column;
    }

    ul {
      width: 100%;
      margin-bottom: 15px;

      li {
        div:first-child {
          font-size: 1rem;
          margin-right: 10px;
        }
        div:last-child {
          font-size: 1.4rem;
          font-weight: 200;
        }
      }
    }
  }
  @media (min-width: 600px) {
    .text-container {
      flex-direction: column;
    }

    ul {
      width: 100%;
      margin-bottom: 15px;

      li {
        div:first-child {
          font-size: 1rem;
          margin-right: 10px;
        }
        div:last-child {
          font-size: 1.4rem;
          font-weight: 200;
        }
      }
    }
  }
  @media (min-width: 900px) {
    .text-container {
      flex-direction: row;
    }

    ul {
      width: 40%;
      margin-bottom: 15px;

      li {
        div:first-child {
          font-size: 0.75rem;
          margin-right: 10px;
        }
        div:last-child {
          font-size: 0.9rem;
          font-weight: 300;
        }
      }
    }
  }
`

export const ServiceImgGrid = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  margin-top: 70px;

  @media (max-width: 599px) {
    flex-direction: column;
    margin-top: 10px;
  }
  @media (min-width: 600px) {
    flex-direction: column;
    margin-top: 40px;
  }
  @media (min-width: 900px) {
    flex-direction: row;
    margin-top: 70px;
  }
`;
export const ImgGridItem = styled.div`
  height: 200px;
  flex: 1;
  background: rgba(0,0,0,0.1);
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 599px) {
    flex: auto;
    margin-right: 0;
    margin-bottom: 15px;
  }
  @media (min-width: 600px) {
    flex: auto;
    margin-right: 0;
    margin-bottom: 15px;
  }
  @media (min-width: 900px) {
    flex: 1;
    margin-right: 40px;
    margin-bottom: 0;
  }
`;

export const ServiceSlideshowImg = styled.div`
  width: 100%;
  height: 300px;
  background: rgba(0,0,0,0.1);
  overflow: hidden;
  margin-bottom: 30px;
`;